import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import imagesLoaded from 'imagesloaded'
import './styles.css'

class Marquee extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.rootRef = undefined
    this.viewportRef = undefined
    this.raf = undefined
    this.x = 0
  }

  componentDidMount() {
    new imagesLoaded(this.rootRef, this.handleMediaReady)
  }

  componentWillUnmount() {
    this.pause()
  }

  handleMediaReady = () => {
    if (this.rootRef) {
      this.rootRef.classList.add('Marquee-ready')
      this.play()
    }
  }

  play = () => {
    this.viewportRef.style.transform = `translate3d(${this.x}px, 0, 0)`
    this.x = (this.x - this.props.speed) % (this.viewportRef.clientWidth / 2)

    this.raf = window.requestAnimationFrame(this.play)
  }

  pause = () => {
    window.cancelAnimationFrame(this.raf)
    this.raf = undefined
  }

  render() {
    const { children, className, speed, ...other } = this.props

    return (
      <div
        className={classnames('Marquee', className)}
        ref={ref => {
          this.rootRef = ref
        }}
        {...other}
      >
        <div
          className="Marquee-viewport"
          ref={ref => {
            this.viewportRef = ref
          }}
          style={{ [speed >= 0 ? 'left' : 'right']: 0 }}
        >
          {children}
          {children}
        </div>
      </div>
    )
  }
}

Marquee.propTypes = {
  speed: PropTypes.number,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Marquee.defaultProps = {
  speed: 1,
}

export default Marquee
