import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.css'

function Typography(props) {
  const {
    className: classNameProp,
    component: componentProp,
    gutterBottom,
    headlineMapping,
    paragraph,
    variant,
    ...other
  } = props

  const className = classnames(
    'Typography',
    `Typography--${variant}`,
    {
      'Typography--gutterBottom': gutterBottom,
      'Typography--paragraph': paragraph,
    },
    classNameProp,
  )

  const Component =
    componentProp || (paragraph ? 'p' : headlineMapping[variant]) || 'span'

  return <Component className={className} {...other} />
}

Typography.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  gutterBottom: PropTypes.bool,
  headlineMapping: PropTypes.object,
  paragraph: PropTypes.bool,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body']),
}

Typography.defaultProps = {
  gutterBottom: false,
  headlineMapping: {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    body: 'p',
  },
  paragraph: false,
  variant: 'body',
}

export default Typography
