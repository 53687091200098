import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './styles.css'

class Flipper extends React.Component {
  state = {
    activeIdx: 0,
  }

  constructor(props) {
    super(props)

    this.rootRef = null
    this.raf = null
  }

  componentDidMount() {
    this.play()
  }

  componentWillUnmount() {
    this.pause()
  }

  handleRef = ref => {
    this.rootRef = ref
  }

  play = () => {
    if (!this.props.speed) return

    //this.raf = window.requestAnimationFrame(this.play)
    this.raf = setInterval(this.next, this.props.speed)
  }

  pause = () => {
    if (!this.props.speed) return

    //window.cancelAnimationFrame(this.raf)
    clearInterval(this.raf)
  }

  next = () => {
    const activeIdx = (this.state.activeIdx + 1) % this.props.children.length
    this.setState({ activeIdx })
  }

  renderChild = (child, i) => {
    const { activeIdx } = this.state

    return React.cloneElement(child, {
      style: {
        ...child.style,
        opacity: activeIdx === i ? 1 : 0,
      },
    })
  }

  render() {
    const { children, className, speed, ...other } = this.props

    return (
      <div
        className={classnames('Flipper', className)}
        ref={this.handleRef}
        {...other}
      >
        {React.Children.map(children, this.renderChild)}
      </div>
    )
  }
}

Flipper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  speed: PropTypes.number,
}

Flipper.defaultProps = {
  speed: 1000,
}

export default Flipper
