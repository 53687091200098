import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ButtonBase from '../ButtonBase'
import './styles.css'

function Button(props) {
  const { children, className, component, disabled, ...other } = props

  return (
    <ButtonBase
      className={classnames('Button', className)}
      component={component}
      {...other}
    >
      {children}
    </ButtonBase>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
}

export default Button
