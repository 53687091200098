import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}

class RootRef extends React.Component {
  componentDidMount() {
    setRef(this.props.rootRef, ReactDOM.findDOMNode(this))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rootRef !== this.props.rootRef) {
      setRef(prevProps.rootRef, null)
      setRef(this.props.rootRef, ReactDOM.findDOMNode(this))
    }
  }

  componentWillUnmount() {
    setRef(this.props.rootRef, null)
  }

  render() {
    return this.props.children
  }
}

RootRef.propTypes = {
  children: PropTypes.element.isRequired,
  rootRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
}

export default RootRef
