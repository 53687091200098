// Ignite shop assets
export default {
  articles: [
    {
      link: {
        label: 'BUY',
        url: 'https://noaignite.myshopify.com/products/carttote',
      },
      cutout: require('./assets/ignite/articles/noa_tote_front.png'),
      images: [
        require('./assets/ignite/articles/noa_tote_front.png'),
        require('./assets/ignite/articles/noa_tote_back.jpg'),
      ],
      name: 'CART TOTE',
      price: '20€',
    },
    {
      link: {
        label: 'BUY',
        url: 'https://noaignite.myshopify.com/products/bpdsweat',
      },
      cutout: require('./assets/ignite/articles/noa_sweatshirt_front.png'),
      images: [
        require('./assets/ignite/articles/noa_sweatshirt_front.png'),
        require('./assets/ignite/articles/noa_sweatshirt_back.jpg'),
      ],
      name: 'BPD SWEAT',
      price: '80€',
    },
    {
      link: {
        label: 'BUY',
        url: 'https://noaignite.myshopify.com/products/stmlong',
      },
      cutout: require('./assets/ignite/articles/noa_longsleeve_front.png'),
      images: [
        require('./assets/ignite/articles/noa_longsleeve_front.png'),
        require('./assets/ignite/articles/noa_longsleeve_back.jpg'),
      ],
      name: 'STM LONG',
      price: '50€',
    },
    {
      link: {
        label: 'BUY',
        url: 'https://noaignite.myshopify.com/products/buytwo4three',
      },
      cutout: require('./assets/ignite/articles/noa_kit_front.png'),
      images: [
        require('./assets/ignite/articles/noa_kit_front.png'),
        require('./assets/ignite/articles/noa_kit_back.jpg'),
      ],
      name: 'BUYTWO4THREE',
      price: '150€',
    },
  ],
  backgrounds: [
    require('./assets/ignite/bgs/background-1.jpg'),
    require('./assets/ignite/bgs/background-2.jpg'),
    require('./assets/ignite/bgs/background-3.jpg'),
    require('./assets/ignite/bgs/background-4.jpg'),
    require('./assets/ignite/bgs/background-5.jpg'),
    require('./assets/ignite/bgs/background-6.jpg'),
    require('./assets/ignite/bgs/background-7.jpg'),
    require('./assets/ignite/bgs/background-8.jpg'),
    require('./assets/ignite/bgs/background-9.jpg'),
  ],
  banner: require('./assets/ignite/banner.png'),
}

// // Boatwood party
// export default {
//   articles: [
//     {
//       link: {
//         label: 'RSVP',
//         url: 'https://boatwood.confetti.events/',
//       },
//       cutout: require('./assets/boatparty/articles/beer_2.png'),
//       images: [
//         require('./assets/boatparty/articles/beer_1.jpg'),
//         require('./assets/boatparty/articles/beer_1.jpg'),
//       ],
//       name: '2019.06.28',
//     },
//     {
//       link: {
//         label: 'RSVP',
//         url: 'https://boatwood.confetti.events/',
//       },
//       cutout: require('./assets/boatparty/articles/cigg_2.png'),
//       images: [
//         require('./assets/boatparty/articles/cigg_1.jpg'),
//         require('./assets/boatparty/articles/cigg_1.jpg'),
//       ],
//       name: '2019.06.28',
//     },
//     {
//       link: {
//         label: 'RSVP',
//         url: 'https://boatwood.confetti.events/',
//       },
//       cutout: require('./assets/boatparty/articles/cond_2.png'),
//       images: [
//         require('./assets/boatparty/articles/cond_1.jpg'),
//         require('./assets/boatparty/articles/cond_1.jpg'),
//       ],
//       name: '2019.06.28',
//     },
//     {
//       link: {
//         label: 'RSVP',
//         url: 'https://boatwood.confetti.events/',
//       },
//       cutout: require('./assets/boatparty/articles/dj_2.png'),
//       images: [
//         require('./assets/boatparty/articles/dj_1.jpg'),
//         require('./assets/boatparty/articles/dj_1.jpg'),
//       ],
//       name: '2019.06.28',
//     },
//     {
//       link: {
//         label: 'RSVP',
//         url: 'https://boatwood.confetti.events/',
//       },
//       cutout: require('./assets/boatparty/articles/silo_2.png'),
//       images: [
//         require('./assets/boatparty/articles/silo_1.jpg'),
//         require('./assets/boatparty/articles/silo_1.jpg'),
//       ],
//       name: '2019.06.28',
//     },
//   ],
//   backgrounds: [
//     require('./assets/boatparty/bgs/background-1.jpg'),
//     require('./assets/boatparty/bgs/background-2.jpg'),
//     require('./assets/boatparty/bgs/background-3.jpg'),
//     require('./assets/boatparty/bgs/background-4.jpg'),
//     require('./assets/boatparty/bgs/background-5.jpg'),
//     require('./assets/boatparty/bgs/background-6.jpg'),
//     require('./assets/boatparty/bgs/background-7.jpg'),
//   ],
//   banner: require('./assets/boatparty/banner.png'),
// }

// // Oakvvood assets
// export default {
//   articles: [
//     {
//       link: {
//         label: 'Buy',
//         url: 'https://shop.oakvvood.se/products/hoodie',
//       },
//       cutout: require('./assets/oakvvood/articles/hoodie-cutout.png'),
//       images: [
//         require('./assets/oakvvood/articles/hoodie-front.jpg'),
//         require('./assets/oakvvood/articles/hoodie-back.jpg'),
//       ],
//       name: 'Okvv Hoodie',
//       price: '80€',
//     },
//     {
//       link: {
//         label: 'Buy',
//         url: 'https://shop.oakvvood.se/products/long-sleeve',
//       },
//       cutout: require('./assets/oakvvood/articles/sleeve-cutout.png'),
//       images: [
//         require('./assets/oakvvood/articles/sleeve-front.jpg'),
//         require('./assets/oakvvood/articles/sleeve-back.jpg'),
//       ],
//       name: 'Okvv T-shirt',
//       price: '50€',
//     },
//     {
//       link: {
//         label: 'Buy',
//         url: 'https://shop.oakvvood.se/products/sweater',
//       },
//       cutout: require('./assets/oakvvood/articles/sweater-cutout.png'),
//       images: [
//         require('./assets/oakvvood/articles/sweater-front.jpg'),
//         require('./assets/oakvvood/articles/sweater-back.jpg'),
//       ],
//       name: 'Okvv Sweater',
//       price: '80€',
//     },
//     {
//       link: {
//         label: 'Buy',
//         url: 'https://shop.oakvvood.se/products/cotton-tote',
//       },
//       cutout: require('./assets/oakvvood/articles/bag-cutout.png'),
//       images: [
//         require('./assets/oakvvood/articles/bag-front.jpg'),
//         require('./assets/oakvvood/articles/bag-back.jpg'),
//       ],
//       name: 'Okvv Tote',
//       price: '15€',
//     },
//     {
//       link: {
//         label: 'Buy',
//         url: 'https://shop.oakvvood.se/products/headless-beanie',
//       },
//       cutout: require('./assets/oakvvood/articles/beanie-front.png'),
//       images: [
//         require('./assets/oakvvood/articles/beanie-front.jpg'),
//         require('./assets/oakvvood/articles/beanie-back.jpg'),
//       ],
//       name: 'Okvv Beanie',
//       price: '10€',
//     },
//   ],
//   backgrounds: [
//     require('./assets/oakvvood/bgs/background-1.jpg'),
//     require('./assets/oakvvood/bgs/background-2.jpg'),
//     require('./assets/oakvvood/bgs/background-3.jpg'),
//     require('./assets/oakvvood/bgs/background-4.jpg'),
//     require('./assets/oakvvood/bgs/background-5.jpg'),
//     require('./assets/oakvvood/bgs/background-6.jpg'),
//     require('./assets/oakvvood/bgs/background-7.jpg'),
//   ],
//   banner: require('./assets/oakvvood/banner.png'),
// }
