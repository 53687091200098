import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.css'

function CardMedia(props) {
  const { className, hoverSrc, src, ...other } = props

  return (
    <figure className={classnames('CardMedia', className)} {...other}>
      <img className="CardMedia-img" src={hoverSrc} alt="" />
      <img className="CardMedia-img" src={src} alt="" />
    </figure>
  )
}

CardMedia.propTypes = {
  className: PropTypes.string,
  hoverSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default CardMedia
