import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import imagesLoaded from 'imagesloaded'
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'
import './styles.css'

class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.rootRef = undefined
    this.slider = undefined
    this.raf = undefined
    this.timeout = undefined
  }

  componentDidMount() {
    new imagesLoaded(this.rootRef, this.handleMediaReady)
  }

  componentWillUnmount() {
    this.pause()
  }

  handleMediaReady = () => {
    this.rootRef.classList.add('Slider-ready')
    this.slider = new Flickity(this.rootRef, {
      draggable: false,
      prevNextButtons: false,
      pageDots: false,
      cellAlign: 'left',
      contain: true,
      wrapAround: true,
      freeScroll: true,
    })

    this.play()
  }

  handleMouseOver = () => {
    this.pause()
  }

  handleMouseOut = () => {
    this.play()
  }

  handleRef = ref => {
    this.rootRef = ref
  }

  play = () => {
    if (!this.props.autoPlaySpeed) return

    this.slider.x = this.slider.x - this.props.autoPlaySpeed
    this.slider.settle(this.slider.x)

    this.raf = window.requestAnimationFrame(this.play)
  }

  pause = () => {
    if (!this.props.autoPlaySpeed) return

    window.cancelAnimationFrame(this.raf)
    this.raf = undefined
  }

  render() {
    const { autoPlaySpeed, children, className, ...other } = this.props

    return (
      <div
        className={classnames('Slider', className)}
        ref={this.handleRef}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
        {...other}
      >
        {children}
      </div>
    )
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  autoPlaySpeed: PropTypes.number,
}

Slider.defaultProps = {
  autoPlaySpeed: 0,
}

export default Slider
