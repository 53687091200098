import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.css'

function Card(props) {
  const { children, className, component: Component, ...other } = props

  return (
    <Component className={classnames('Card', className)} {...other}>
      {children}
    </Component>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
}

Card.defaultProps = {
  component: 'article',
}

export default Card
