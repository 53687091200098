import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.css'

function ButtonBase(props) {
  const { children, className, component, disabled, ...other } = props

  let ComponentProp = component

  if (ComponentProp === 'button' && other.href) {
    ComponentProp = 'a'
  }

  const buttonProps = {}
  if (ComponentProp === 'button') {
    buttonProps.type = other.type || 'button'
    buttonProps.disabled = disabled
  } else {
    buttonProps.role = 'button'
  }

  return (
    <ComponentProp
      className={classnames('ButtonBase', className)}
      {...buttonProps}
      {...other}
    >
      {children}
    </ComponentProp>
  )
}

ButtonBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
}

ButtonBase.defaultProps = {
  component: 'button',
}

export default ButtonBase
