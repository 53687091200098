import React from 'react'
import EventListener from 'react-event-listener'
import 'sanitize.css'
import data from '../../data'
import Typography from '../../components/Typography'
import ButtonBase from '../../components/ButtonBase'
import Button from '../../components/Button'
import Flipper from '../../components/Flipper'
import Marquee from '../../components/Marquee'
import Slider from '../../components/Slider'
import Card from '../../components/Card'
import CardMedia from '../../components/CardMedia'
import Scatter from '../../components/Scatter'
import './styles.css'

class App extends React.Component {
  state = {
    isMobile: false,
  }

  componentDidMount() {
    this.handleResize()
  }

  handleResize = () => {
    const { isMobile } = this.state

    if (isMobile && window.innerWidth >= 600) {
      this.setState({ isMobile: false })
    }
    if (!isMobile && window.innerWidth < 600) {
      this.setState({ isMobile: true })
    }
  }

  render() {
    const { isMobile } = this.state

    const articles = data.articles.map((article, idx) => ({
      data: article,
      html: (
        <Card key={idx} component={ButtonBase} href={article.link.url}>
          <CardMedia
            src={article.images[0]}
            hoverSrc={article.images[1]}
            style={{ marginBottom: 10 }}
          />

          {article.name && (
            <Typography variant="h2" style={{ marginBottom: 12 }}>
              {article.name}

              {article.price && (
                <span style={{ fontFamily: '"Old Standard TT", serif' }}>
                  {` ${article.price}`}
                </span>
              )}
            </Typography>
          )}

          <Button component="span" role={undefined}>
            {article.link.label}
          </Button>
        </Card>
      ),
    }))

    return (
      <div className="App">
        <EventListener target="window" onResize={this.handleResize} />

        <Flipper className="App-Flipper" speed={450}>
          {Array.from(data.backgrounds, (src, index) => (
            <img key={index} className="App-Flipper-child" src={src} alt="" />
          ))}
        </Flipper>

        <Marquee
          key={`App-MarqueeTop-${isMobile}`}
          className="App-MarqueeTop"
          speed={1}
        >
          {Array.from(new Array(2), (src, index) => (
            <img key={index} src={data.banner} alt="NoA Ignite" />
          ))}
        </Marquee>

        <div className="App-grid">
          {articles.map((article) => article.html)}
        </div>

        <div className="App-SliderWrapper">
          <Slider className="App-Slider" autoPlaySpeed={3.5}>
            {[...articles, ...articles].map((article, idx) => (
              <Scatter
                key={idx}
                className="App-Slider-item"
                image={article.data.cutout}
              >
                {article.html}
              </Scatter>
            ))}
          </Slider>
        </div>

        <Marquee
          key={`App-MarqueeBottom-${isMobile}`}
          className="App-MarqueeBottom"
          speed={isMobile ? 1 : -1}
        >
          {Array.from(new Array(2), (src, index) => (
            <img key={index} src={data.banner} alt="NoA Ignite" />
          ))}
        </Marquee>
      </div>
    )
  }
}

export default App
