import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import RootRef from '../RootRef'
import './styles.css'

class Scatter extends React.Component {
  images = []

  handleMouseOver = () => {
    const rootRect = this.rootRef.getBoundingClientRect()

    this.overlayRef.style.display = 'block'
    this.fixateElementByRect(this.cardRef, rootRect)

    this.images.forEach((image, i) => {
      this.fixateElementByRect(image, rootRect)

      setTimeout(() => {
        image.style.left = `${10 * Math.floor(Math.random() * 10)}%`
        image.style.top = `${10 * Math.floor(Math.random() * 10)}%`
        image.style.transform = `scale(${Math.random() + 1.5})`
      }, (i + 1) * 50)
    })
  }

  handleMouseOut = () => {
    this.overlayRef.removeAttribute('style')
    this.images.forEach(image => {
      image.removeAttribute('style')
    })
  }

  fixateElementByRect(node, rect) {
    node.style.width = `${rect.width}px`
    node.style.left = `${rect.left}px`
    node.style.top = `${rect.top}px`
    node.style.position = 'fixed'
  }

  render() {
    const { children, className, image, ...other } = this.props

    this.images = []

    return (
      <div
        className={classnames('Scatter', className)}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOut}
        ref={ref => {
          this.rootRef = ref
        }}
        {...other}
      >
        {ReactDOM.createPortal(
          <div
            className="Scatter-overlay"
            ref={ref => {
              this.overlayRef = ref
            }}
          >
            {Array.from(new Array(15), (val, i) => (
              <img
                key={i}
                src={image}
                alt=""
                ref={ref => {
                  if (ref) {
                    this.images.push(ref)
                  }
                }}
              />
            ))}

            <RootRef
              rootRef={ref => {
                this.cardRef = ref
              }}
            >
              {children}
            </RootRef>
          </div>,
          document.body,
        )}

        {children}
      </div>
    )
  }
}

Scatter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
}

export default Scatter
